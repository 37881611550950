import React, { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import AppContext from '../../AppContext';
import { Submit, Input, Checkbox } from '../../components/Form';
import Layout from '../../components/Layout/Layout';
import './Login.scss';

function Login(props) {
  const { setConnected } = useContext(AppContext);
  const [error, setError] = useState(false);

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = (data) => {
    localStorage.setItem('login', data.login);
    fetch(`${process.env.REACT_APP_WEBSERVICES}/login_check`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        login: data.login,
        password: data.password,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.secretKey) {
          setConnected(true);
          localStorage.setItem('auth_token', data.secretKey);
          fetch(`${process.env.REACT_APP_WEBSERVICES}/auth_check`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'TD-SECRET-KEY': data.secretKey,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              localStorage.setItem('auth', JSON.stringify(data));
              if (
                data.isEmailVerified === true &&
                data.isBillingInfoConfirmed === true
              ) {
                props.history.push('/home');
              } else if (data.isEmailVerified === false) {
                props.history.push('/approve-email');
              } else if (data.isBillingInfoConfirmed === false) {
                props.history.push('/validate-personal-infos');
              }
            });
        } else if (data.migrationToken) {
          localStorage.setItem('migration_token', data.migrationToken);
          props.history.push('/approve-email');
        } else {
          setError('Les identifiants renseignés sont erronés');
        }
      });
  };

  useEffect(() => {
    localStorage.removeItem('auth');
    localStorage.removeItem('auth_token');
    localStorage.removeItem('migration_token');
    localStorage.removeItem('email-approvement-token');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userInfos');
  }, []);
  return (
    <Layout>
      <div className="Login">
        <div className="Login__content">
          <h1 className="Login__title">Connectez-vous</h1>
          <div className="Login__connection">
            <form className="Login__form" onSubmit={handleSubmit(onSubmit)}>
              <Input
                label="E-mail ou identifiant Moovia"
                name="login"
                placeholder="E-mail ou identifiant Moovia"
                register={register({ required: true })}
                errors={errors}
              />
              <Input
                type="password"
                label="Mot de passe"
                name="password"
                register={register({ required: true })}
                errors={errors}
              />
              <Checkbox
                label="Rester connecté(e)"
                name="stayConnected"
                register={register}
                errors={errors}
              />
              <Submit>Se connecter</Submit>
              {error && <p className="Login__error">{error}</p>}
            </form>
            <Link to="/forgot-password/form" className="Login__forgot">
              Mot de passe oublié ?
            </Link>
          </div>
          <div className="Login__help">
            <p className="Login__help__bold">
              Vous avez besoin d’aide pour vous connecter à votre espace client
              ?
            </p>
            <p className="Login__help__text">
              Pour toutes questions relatives à votre abonnement, nous vous
              remercions de bien vouloir contacter la maison du stationnement ou
              l’accueil de votre parking.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default withRouter(Login);
