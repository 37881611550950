import React from 'react';
import './Footer.scss';

export default function Footer() {
  return (
    <div className="Footer">
      <div className="Footer__content">
        <span className="Footer__wrap">
          À retrouver sur le site de{' '}
          <a
            href="https://moovia-stationnement.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="Footer__link"
          >
            moovia-stationnement.com
          </a>{' '}
          :{' '}
        </span>
        <a
          href="https://moovia-stationnement.com/faq"
          rel="noopener noreferrer"
          target="_blank"
          className="Footer__link2"
        >
          F.A.Q.
        </a>
        <a
          href="https://moovia-stationnement.com/mentions-legales"
          rel="noopener noreferrer"
          target="_blank"
          className="Footer__link2"
        >
          Mention légales
        </a>
        <a
          href="https://moovia-stationnement.com/confidentialite"
          rel="noopener noreferrer"
          target="_blank"
          className="Footer__link2"
        >
          Données personnelles
        </a>
        <a
          href="https://moovia-stationnement.com/cookies"
          rel="noopener noreferrer"
          target="_blank"
          className="Footer__link2"
        >
          Politique de cookies
        </a>
      </div>
    </div>
  );
}
