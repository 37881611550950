import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import Popup from 'reactjs-popup';
import FileSaver from 'file-saver';
import AppContext from '../../AppContext';
import LayoutConnected from '../../components/LayoutConnected/LayoutConnected';
import { Select } from '../../components/Form';
import './Home.scss';
import secure from '../../img/secure.svg';
import paybox from '../../img/paybox.png';
import download from '../../img/download.svg';
import 'reactjs-popup/dist/index.css';

const parse = require('html-react-parser');

const dlFacture = (factureId) => {
  let existingInvoice = false;
  fetch(`${process.env.REACT_APP_WEBSERVICES}/invoices/${factureId}/download`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'TD-SECRET-KEY': localStorage.getItem('auth_token'),
    },
  })
    .then((response) => {
      if (response.status !== 404) {
        existingInvoice = true;
        return response.blob();
      }
      alert('La facture est indisponible, contactez nous');
    })
    .then((blob) => {
      if (existingInvoice === true) {
        FileSaver.saveAs(blob, `facture-${factureId}.pdf`);
      }
    });
};

const Td = ({ children, head, type }) => {
  if (head) {
    return (
      <td className={`Td__mobile ${type || ''}`}>
        <div className="Td__content">
          <div className="Td__head">{head}</div>
          <div>{children}</div>
        </div>
      </td>
    );
  }
  return <td className="Td">{children}</td>;
};

export default function Home() {
  const { service } = useContext(AppContext);
  const [facturesList, setFactures] = useState([]);
  const [paymentForm, setPayment] = useState('');
  const [subscriptionList, setSubscriptions] = useState([]);
  const { register, handleSubmit } = useForm();
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  const payAction = (factureId) => {
    service(`/invoices/${factureId}/pay/paybox`, 'GET').then((data) => {
      setPayment(parse(data.html));
      setOpen((o) => !o);
    });
  };
  const filter = (value) => {
    service('/account', 'GET').then((data) => {
      if (!value.abonnementId) {
        setFactures(data.invoices);
        return;
      }
      setFactures(
        data.invoices.filter(
          (facture) => facture.subscriptionId === value.abonnementId
        )
      );
    });
  };

  useEffect(() => {
    service('/account', 'GET').then((data) => {
      if (data.invoices) setFactures(data.invoices);
      if (data.subscriptions) setSubscriptions(data.subscriptions);
    });
  }, []);

  return (
    <LayoutConnected>
      <div className="Home">
        <Popup modal open={open} onClose={closeModal} nested>
          {(close) => (
            <div className="modal">
              <div className="header">
                {' '}
                Vous allez être redirigé vers une page de paiement, veuillez
                confirmer en cliquant sur le bouton ci-dessous :
              </div>
              {paymentForm && <div className="actions">{paymentForm}</div>}
            </div>
          )}
        </Popup>

        <h1 className="Home__title">Mes factures</h1>
        <p className="Home__text">
          <b>INFORMATION</b> : une fois vos factures payées en ligne, quelques
          minutes peuvent être nécessaires à la mise à jour de l&apos;état dans
          votre Espace Client. Pour télécharger vos factures qui datent depuis
          plus d’une année, faites-nous en la demande par email à l’adresse{' '}
          <a href="mailto:servicesclients.moovia@transdev.com">
            servicesclients.moovia@transdev.com
          </a>
        </p>
        <div className="Home__brand">
          <img src={secure} alt="" className="Home__brand__secure" />
          Paiment en ligne sécurisé par
          <img src={paybox} alt="paybox" className="Home__brand__paybox" />
        </div>
        <form onChange={handleSubmit(filter)}>
          <Select label="Abonnement" name="abonnementId" register={register}>
            <option value="">Tous mes abonnements</option>
            subscriptionList
            {subscriptionList.map((subscription, i) => {
              return (
                <option key={subscription.id} value={subscription.id}>
                  {subscription.parkName} - {subscription.id}
                </option>
              );
            })}
            ;
          </Select>
        </form>

        <table className="Home__table" role="presentation">
          <caption style={{ display: 'none' }}>Mes factures</caption>
          <tbody>
            <tr className="Home__table__head">
              <th scope="col" role="columnheader">
                Date
              </th>
              <th scope="col" role="columnheader">
                Abonnement
              </th>
              <th scope="col" role="columnheader">
                Facturation
              </th>
              <th scope="col" role="columnheader">
                Montant
              </th>
              <th scope="col" role="columnheader">
                Échéance
              </th>
              <th scope="col" role="columnheader">
                Statut
              </th>
              <th scope="col" role="columnheader" aria-label="Téléchargement" />
            </tr>
            {facturesList.map((facture, i) => {
              return (
                <tr className="Home__table__body" key={i}>
                  <Td head="Date" type="flex">
                    {facture.createdAt}
                  </Td>
                  <Td head="Abonnement" type="flex">
                    <div className="Home__table__body__facture">
                      {facture.subscriptionId}
                    </div>
                    {facture.parkName}
                  </Td>
                  <Td head="Facturation" type="flex">
                    {facture.label}
                  </Td>
                  <Td head="Montant" type="block">
                    {facture.amount}€
                  </Td>
                  <Td head="Échéance" type="block">
                    {facture.maxPaymentAt}
                  </Td>
                  {facture.status === 'unpaid' && facture.payable === true && (
                    <Td head="Statut" type="block">
                      <button
                        onClick={() => {
                          payAction(facture.id);
                        }}
                        className="Home__table__body__noPaid"
                      >
                        Payer
                      </button>
                    </Td>
                  )}
                  {facture.status === 'unpaid' && facture.payable === false && (
                    <Td head="Statut" type="block">
                      <span className="Home__table__body__unpaid">
                        À payer en parc
                      </span>
                    </Td>
                  )}
                  {facture.status === 'online' &&
                    parseFloat(facture.balance) === 0 && (
                      <Td head="Statut" type="block">
                        <span className="Home__table__body__paid">
                          Payée en <span>ligne&nbsp;</span>
                        </span>
                      </Td>
                    )}
                  {facture.status === 'park' &&
                    parseFloat(facture.balance) === 0 && (
                      <Td head="Statut" type="block">
                        <span className="Home__table__body__paid">
                          Payée à l&apos;accueil
                        </span>
                      </Td>
                    )}
                  {(facture.status === 'partial_park' ||
                    facture.status === 'partial_online') &&
                    parseFloat(facture.balance) > 0 &&
                    facture.payable === true && (
                      <Td head="Statut" type="block">
                        <button
                          onClick={() => {
                            payAction(facture.id);
                          }}
                          className="Home__table__body__noPaid"
                        >
                          Payer le solde de : {facture.balance}€
                        </button>
                      </Td>
                    )}
                  {(facture.status === 'partial_park' ||
                    facture.status === 'partial_online') &&
                    parseFloat(facture.balance) > 0 &&
                    facture.payable === false && (
                      <Td head="Statut" type="block">
                        <span className="Home__table__body__unpaid">
                          Solde de {facture.balance}€ à payer en parc
                        </span>
                      </Td>
                    )}
                  <Td>
                    <button
                      type="button"
                      aria-label="Télécharger"
                      className="Home__table__download"
                      onClick={() => {
                        dlFacture(facture.id);
                      }}
                    >
                      <img
                        src={download}
                        alt=""
                        className="Home__table__download__icon"
                      />
                    </button>
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </LayoutConnected>
  );
}
