import React, { useContext } from 'react';
import AppContext from '../../AppContext';
import './HeaderConnected.scss';
import logo from '../../img/logo-moovia.png';

export default function HeaderConnected() {
  const { toggleMenu, menu } = useContext(AppContext);
  return (
    <header className={`HeaderConnected ${menu ? '-openedMenu' : ''}`}>
      <div className="HeaderConnected__container">
        <div className="HeaderConnected__content">
          <a href="/home" className="HeaderConnected__home">
            <img
              src={logo}
              alt="logo Moovia"
              className="HeaderConnected__logo"
            />
          </a>
          <button
            type="button"
            href="https://moovia-stationnement.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="HeaderConnected__burger"
            aria-label="menu"
            onClick={toggleMenu}
          >
            <div className="HeaderConnected__burger__anim">
              <span />
            </div>
          </button>
        </div>
      </div>
      <div className="HeaderConnected__banner">
        <span className="HeaderConnected__banner__title">
          <span>Bienvenue sur votre espace client Moovia</span>
        </span>
      </div>
    </header>
  );
}
